import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import MainContent from './MainContent';
import Upload from './Upload';

function App() {
    return (
        <Router>
        <div className="App">
            <header className="App-header">
            <nav>
                        
                    </nav>
            </header>
            <main>
            <Routes>
                        <Route path="/" element={<MainContent />} />
                        <Route path="/upload" element={<Upload />} />
                    </Routes>
            </main>
        </div>
        </Router>
    );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-container h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
}

.upload-form {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.upload-label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

.upload-input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.upload-button {
    padding: 0.75rem 1.5rem;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #218838;
}

.upload-message {
    margin-top: 1rem;
    color: #d9534f;
}

.sketch-link {
    display: block;
    text-align: center;
    margin-top: 2rem;
    color: #007bff;
    text-decoration: none;
}

.sketch-link:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/Upload.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".upload-container {\n    max-width: 800px;\n    margin: 2rem auto;\n    padding: 2rem;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.upload-container h1 {\n    text-align: center;\n    margin-bottom: 2rem;\n    color: #333;\n}\n\n.upload-form {\n    margin-bottom: 1.5rem;\n    padding: 1rem;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n}\n\n.upload-label {\n    margin-bottom: 0.5rem;\n    font-weight: bold;\n    color: #333;\n}\n\n.upload-input {\n    margin-bottom: 1rem;\n    padding: 0.5rem;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    width: 100%;\n}\n\n.upload-button {\n    padding: 0.75rem 1.5rem;\n    background-color: #28a745;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.upload-button:hover {\n    background-color: #218838;\n}\n\n.upload-message {\n    margin-top: 1rem;\n    color: #d9534f;\n}\n\n.sketch-link {\n    display: block;\n    text-align: center;\n    margin-top: 2rem;\n    color: #007bff;\n    text-decoration: none;\n}\n\n.sketch-link:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

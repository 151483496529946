// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Exo 2', Verdana, sans-serif;
}
@media (min-width: 1200px) {
  .grid .col-md-4:nth-child(3n+1) {
    clear: left;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .grid .col-sm-6:nth-child(2n+1) {
    clear: left;
  }
}
td {
  padding-right: 1em;
}
.control-key {
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 0.6em;
  padding: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: #f5f5f5;
  margin-left: 1em;
}
.thumbnail {
    border: 0 none;
    box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;;;AAIA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,yCAAyC;AAC3C;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iCAAiC;EACjC,oBAAoB;EACpB,cAAc;EACd,mBAAmB;EACnB,oBAAoB;EACpB,yBAAyB;EACzB,gBAAgB;AAClB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\nbody {\n  font-family: 'Exo 2', Verdana, sans-serif;\n}\n@media (min-width: 1200px) {\n  .grid .col-md-4:nth-child(3n+1) {\n    clear: left;\n  }\n}\n@media (min-width: 768px) and (max-width: 1199px) {\n  .grid .col-sm-6:nth-child(2n+1) {\n    clear: left;\n  }\n}\ntd {\n  padding-right: 1em;\n}\n.control-key {\n  border: 2px solid rgba(0,0,0,0.2);\n  border-radius: 0.6em;\n  padding: 0.2em;\n  padding-left: 0.5em;\n  padding-right: 0.5em;\n  background-color: #f5f5f5;\n  margin-left: 1em;\n}\n.thumbnail {\n    border: 0 none;\n    box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

body {
  font-family: 'Exo 2', Verdana, sans-serif;
}
@media (min-width: 1200px) {
  .grid .col-md-4:nth-child(3n+1) {
    clear: left;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .grid .col-sm-6:nth-child(2n+1) {
    clear: left;
  }
}
td {
  padding-right: 1em;
}
.control-key {
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 0.6em;
  padding: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: #eee;
  margin-left: 1em;
}
.thumbnail {
    border: 0 none;
    box-shadow: none;
}
.logo-gradient {
  background: linear-gradient(to right, #ff9999, #ffff99, #99ff99, #99ffff);
  background-clip: text;
  -webkit-background-clip: text;
  color:rgba(0,0,0,0) !important;
  text-shadow: none !important;
}

.image-link {
  display: block;
  background-color: white;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";;AAEA;EACE,yCAAyC;AAC3C;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iCAAiC;EACjC,oBAAoB;EACpB,cAAc;EACd,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,gBAAgB;AAClB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;EACE,yEAAyE;EACzE,qBAAqB;EACrB,6BAA6B;EAC7B,8BAA8B;EAC9B,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["\n\nbody {\n  font-family: 'Exo 2', Verdana, sans-serif;\n}\n@media (min-width: 1200px) {\n  .grid .col-md-4:nth-child(3n+1) {\n    clear: left;\n  }\n}\n@media (min-width: 768px) and (max-width: 1199px) {\n  .grid .col-sm-6:nth-child(2n+1) {\n    clear: left;\n  }\n}\ntd {\n  padding-right: 1em;\n}\n.control-key {\n  border: 2px solid rgba(0,0,0,0.2);\n  border-radius: 0.6em;\n  padding: 0.2em;\n  padding-left: 0.5em;\n  padding-right: 0.5em;\n  background-color: #eee;\n  margin-left: 1em;\n}\n.thumbnail {\n    border: 0 none;\n    box-shadow: none;\n}\n.logo-gradient {\n  background: linear-gradient(to right, #ff9999, #ffff99, #99ff99, #99ffff);\n  background-clip: text;\n  -webkit-background-clip: text;\n  color:rgba(0,0,0,0) !important;\n  text-shadow: none !important;\n}\n\n.image-link {\n  display: block;\n  background-color: white;\n  width: fit-content;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
